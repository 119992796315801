
body.home{
	main{
		position: relative;
		top:-45px;
		margin-bottom: -45px;
		background-color: #fff;

	}
	div#overlay{
		position: relative;
		background-image: url(../../images/home/overlay.png);
		// background-position: -15px 0px;
		background-position: 0px 0px;
		
		@media #{$small-up} {
			background-image: radial-gradient(43% 32%,rgba(128,127,127,0.3) 10%,rgba(0,0,0,0.5) 100%);
			background-position: 0px 40px;
		} 

		@media #{$medium-up} {
			background-image: radial-gradient(43% 32%,rgba(128,127,127,0.3) 10%,rgba(0,0,0,0.5) 100%);
			background-position: 0px 40px;
		} 	

		@media #{$large-up} { 
			// background-image: url(../../images/home/desktop-overlay-sm.png);
			//background-image: radial-gradient(42% 81%,rgba(128,127,127,0.3) 10%,rgba(0,0,0,.6) 91%);
			//new darker
			background-image: radial-gradient(37% 41%,rgba(128,127,127,.2) -10%,rgba(0,0,0,.6) 91%);
			background-position: 0px 40px;
		}
		//old:
		//background-image: radial-gradient(52% 81%, rgba(128,127,127, .6) 50%, rgba(0,0,0, .6) 82%); 
		// better:

		//top:-357px;
		// background-position: -15px -40px;
		
		height: 100vh;
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 10;
		position: absolute;
		width: 100%;


	}



	div#content{
		position: relative;
		z-index: 10;
		//top:-350px;
		@include single-transition(all, 1s, ease-in-out);
		@media #{$medium-up} { 
			top: 50px;		
		}	
		@media #{$large-up} { 
			//top: 250px;		
		}	
	}
	div#header{
		//to put at top behind nav
		position: relative;
		background-color: #fff;
		// background-image: 
			// radial-gradient(52% 81%, rgba(128,127,127, .6) 50%, rgba(0,0,0, .6) 82%), 
			//url(../../images/home/overlay.png),
			// url(../../images/home/desk_phone.jpg);
	
		background-position: 
			//-20px -40px,
			// 0px -40px;
			0px -65px;

		@media #{$small-up} { 
				background-image: url(../../images/home/new-england-soccer-classic_sm_opti.jpg);
				// background-image: url(../../images/home/desk_plus_overlay.jpg);
				background-position: center;
			}

		@media #{$medium-up} { 
				background-image: url(../../images/home/new-england-soccer-classic_sm_opti.jpg);
				// background-image: url(../../images/home/desk_plus_overlay.jpg);
				background-position: 
			//-20px -40px,
			center;
			// 0px -65px;
			}

		@media #{$large-up} { 
			//background-image: url(../../images/home/large-faded-desk.jpg);
			background-position: 
			//-20px -40px,
			// 0px -40px;
			// 0px -65px;

		}
	    background-repeat:
	    	//no-repeat,
            no-repeat;

		height: 100vh;
		background-size: 
			//cover,
			cover;

		overflow-x: hidden;
		overflow-y: hidden;


		img#logo{
			width:150px;
			position: relative;
			top: 60px;
			@include single-transition(all, 1s, ease-in-out);
			@media #{$medium-up} { 
				width:160px;

			}
		}

		h2{
			position: relative;
			top: 90px;
			font-weight: 300!important;
    		font-size: 2.5em;
    		color: #fff;
    		line-height: 1.25em;
    		@media #{$medium-up} { 
				font-size: 4em;

			}
		}

		a.button{
			/* Rectangle 50 Copy 2: */
			top: 150px;
			background-color: rgba($nesc-blue,.75);
			box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
			color: #fff;
			font-size: 1.25rem;
			&:hover{
				background-color: rgba($nesc-blue,.9);
				text-decoration: none;
			}
			span{
				// color: #fff;
				// opacity: 1;
			}
		}
		
		div#wrapper{
		position: relative;
		// overflow-x: hidden;
		// overflow-y: hidden;

		}


		





	}

	

}

h2.homeProjects, h2.homeBlog{
		margin-top: 40px;
		margin-bottom: 20px;
		text-transform: uppercase;
		color: $horgan-brown;
		font-family: $lato;
		font-weight: 300;
			span.section{

				font-weight: 500;
				color: $horgan-green;
			}
			span.title{
			text-transform: none;	
			color: $horgan-green;
			font-weight: 500;
			}
		}
ul{
	li{
		}h3, a h3{
			// background: rgba($horgan-ltgreen,.75);
		 //    color: #fff;
		 //    font-weight: 300;
		 //    position: absolute;
		 //    padding: 8px;
		    /* font-size: 1.7em; */
		    /* top: 20px; */
		    // margin: 0 12px 0 0;
		    // text-align: right;
		    // width: 100%;
		    font-size: 1.25rem;
		}
	}
	

