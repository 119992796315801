
[data-magellan-expedition]{
	padding: 0px;
	border-bottom: 1px solid #e8e8e8;
	z-index: 98;
	// right: 0;
	.sub-nav{
	background-color: #fff;
	padding: 10px 10px 10px 0;
	// margin: 0 auto;
	// width: fit-content;
	


	}

}
.subNav{
	// margin-top: 50px;
	// margin-bottom: 50px;
}
.sub-nav dt.active a, .sub-nav dd.active a, .sub-nav li.active a {
    border-radius: 3px;	
	background: #CA2629;
	color: #FFFFFF;
	cursor: default;
	font-weight: normal;
	padding: 0.16667rem 0.88889rem;
	&:hover{
		background: #3B4597;
	}
	img{
		background:none;
	}
}

a.icony{
	background:none !important;
	padding:0px;
}

img.icony{
	height: 40px;


}

.sub-nav dd{
	float: none;
	display: inline-block;
	margin-left: 0px;

}

.sub-nav dd a.icony{
	padding:0px;
}	

a.application{
	text-align: left;
	padding-left: .5rem !important;
}


nav.top-bar{
	background-color: #fff; //to hide magellan nav on tournament page
}

.sub-nav{
	@media #{$small-only}{
		margin-top: -1.18rem;
	}
}

body.new-england-soccer-classic{
	[data-magellan-expedition]{
		// margin-top:0px;
	}
	h1,h2,h3,h4,h5{
		color: #3B4597;
	}

	.head{

		text-align: center;
		h1{
			margin-top: 30px;
			color: #fff;
			text-shadow: 1px 1px 2px #030303;
		}
		h2{
			color: #fff;
			text-shadow: 1px 1px 2px #030303;
		}
		h3{
			color: #fff;
			text-shadow: 1px 1px 2px #030303;
		}
		img{
			width: 200px;
			margin-bottom: 30px;
		}
	}

	.mainFooter{
		h2{color:#fff;}
	}
	
	h2.sectiony{
		color: #fff;
		background-color: #3B4597;
		opacity: .85;
		text-align: center;
	}
	section.sectiony{
		border-left: 1px solid #3B4597;
		padding-left: 10px;
	}

}

body.fc-usa-coastal-cup{
	[data-magellan-expedition]{
		// margin-top:0px;
	}
	h1,h2,h3,h4,h5{
		color: #3B4597;
	}

	.head{

		text-align: center;
		h1{
			margin-top: 30px;
			color: #fff;
			text-shadow: 1px 1px 2px #030303;
		}
		h2{
			color: #fff;
			text-shadow: 1px 1px 2px #030303;
		}
		h3{
			color: #fff;
			text-shadow: 1px 1px 2px #030303;
		}
		img{
			width: 200px;
			margin-bottom: 30px;
		}
	}

	.mainFooter{
		h2{color:#fff;}
	}
	
	h2.sectiony{
		color: #fff;
		background-color: #bcc93d;
		opacity: .85;
		text-align: center;
	}
	section.sectiony{
		border-left: 1px solid #bcc93d;
		padding-left: 10px;
	}

}

.top-bar-section .dropdown{
	// clip: rect(1px, 1px, 1px, 1px);
}

.tourneyHead{
	background-image: url(../../images/tournaments/nesc-head.jpg);
	background-size: 100%;
	// background-position-y:-400px;
	margin-bottom: 50px;
	background-repeat: no-repeat;
	@media #{$small-up}{
		background-position-y:0px;
	}
	@media #{$medium-up}{
		background-position-y:-100px;
	}
	@media #{$large-up}{
		// background-position: 0 0; 
		background-position-y:-400px;
	}
	


}

.subContent{
	margin-top: 50px;
}

.importantDates{
	margin-left: 10px !important;
}