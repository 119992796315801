// nav
//to center top nav, but "name" on left puts it off center
// nav.top-bar {
// text-align:center;
// }
// section.top-bar-section {
// display: inline-block;
// }

//colors

.red{
	color: red;
}

.purple{
	color: $horgan-purple;
}

.green{
	color: $horgan-green;
}

.orange{
	color: $horgan-orange;
}

.dkblue{
	color: $horgan-dkblue;
}

.overlay{
	background-color: rgba(0,0,0,.5);
}

.block{
	display: block;
}

.transPanel{
	background-color: rgba(255,255,255,.5);
	border-radius: 10px;
	padding-top: 30px;
}




//nesc
.nesc{
	h1, h2{
		color: $nesc-red;
	}
	h3{
		color: $nesc-blue;
	}
}


.coastal{
	h1,h2{
		color: $coastal-blue;
	}
	h3{
		color: $coastal-green;
	}
}

.cran{
	h1,h2{
		color: $cran-red;
	}
	h3{
		color: $cran-blue;
	}
}



.background-on{
	@include single-transition(background-image, 1s, ease-in-out);
	background-color: #fff;
	background-image: url("../../images/shared/nav-bg-mobile.png");
}


 body{
// 	background-image: url(../../images/shared/clouds.png);
// background-color: rgba(190,221,234,1);
// color: $learning-dkgrey;
// background-image: url(../../images/shared/clouds-sm.png);
// background-size: cover;
background-color: #fff;
p{
	font-size: 1.0625rem;
	font-weight: 300;
	letter-spacing: .25px;
}


@media #{$small-only}{
	p{
		font-size: 1.25rem;
		font-weight: 300;
		a{
			font-weight: 400;
		}
	}

}

color: $horgan-brown;
a{
	color: $nesc-blue;
	&:hover{
				text-decoration: underline;
				color: $nesc-blue;
			}
}
 
}

h2{
	// text-align: center;
	// text-transform: uppercase;
	// color: $horgan-brown;
	// font-family: $lato;
	// font-weight: 300;
	// span.section{
	// 	text-align: center;
	// 	font-weight: 500;
	// 	color: $horgan-green;
	// }
}


nav.top-bar{
	z-index: 11; // to allow for clickable links and proper scrolling of sub-services pages

}

// centered top bar with left logo
// larger screen styles

ul.center-buttons {
	// margin-left: 200px;

}

.top-bar-section ul.center-buttons{
	// margin-left: 200px !important; //adjusted from centered nav with right icons
	margin: 0 auto;
}
@media only screen and (min-width: 40em) {

  .top-bar {
    text-align: center;
  }

  ul.center-buttons {
    display: inline-block;
    //margin-left: -200px;
    li a{
    	&:hover{
		color: $horgan-ltblue;
	}
    }
  }

  .logo {
    left: 10px;
    top: 10px;
    position: absolute;
    z-index: 100;

  }
}



// some mobile resets

.logo {
 // max-width: 2rem; removed?
//  margin-top: -3px;
  margin-left: 5px;

}

.menu-icon {
  padding-top: 1.2rem;
}



.top-bar.expanded {
  //background: #333333;
  // background-color: rgba(0,0,0, .5);
  height: 1000px !important;
  //height: 252px !important;
}


//end centered with left logo

// #trees-1, #excavation-1, #landscapes-1, #hardscapes-1{
// 	background: url(../../images/home/home-1-bg.jpg)
// 	// background: url(../../images/home/home-2-bg.jpg)
// 	// top / 100% /* position / size */
//     no-repeat                /* repeat */; 
// 	h1{

// 		@media #{$small-only} { 
// 			font-size: 2em;
// 			margin-top: 20px;
// 			margin-bottom: 40px;
// 		}
// 		color:#fff;
// 		text-align: center;
// 		span{
// 			font-weight: 900;
// 			text-transform: uppercase;
// 		}
// 		/* tag: */
// 		// line-height: 97px;
// 		text-shadow: 1px 1px 2px #030303;
// 		margin-top:  80px;
// 		margin-bottom: 80px;
// 		font-size: 3.75em;


// 	}
// 	height: 100vh;
// 	margin-top: -45px;

// 	.row section#section-1{
// 		margin-top:10%;
// 	}
// }
.top-bar{

	//this needs to happen at a certain pixel point
		// background-color: $horgan-offwht;
		// background-image: url("../../images/shared/nav-bg-mobile.png");
	//end this
}	
.fixed .top-bar{
	
	//this needs to happen at a certain pixel point
	// background-color: $horgan-offwht;
	// box-shadow: -2px 1px 1px rgba(0,0,0,.25);
	//end this

	// border-bottom: 1px solid #aacee7;
    
}
nav.top-bar ul, .top-bar-section li:not(.has-form) a:not(.button){
	//background: #fff; //was transparent

	//background-color: #fff;
    // border-bottom: 1px solid #aacee7;
    // box-shadow: -2px 1px 1px rgba(0,0,0,.05);
}

.top-bar-section{
	background-color: #000;
	
    // border-bottom: 1px solid #aacee7;
    box-shadow: -2px 1px 1px rgba(0,0,0,.1);
    padding-top: 10px;
    @media #{$medium-up} {
    	height: 60px;
background-color: #fff;
    }
    @media #{$large}{
		
    }
}

nav.top-bar ul li a{
	text-transform: uppercase;
	font-weight: 500;

}

.top-bar-section ul li {
	a{  
		@media #{$medium-up} {
			@include single-transition(color, 300ms, ease-in-out);
			&:hover{
		color: $horgan-ltblue;
	}
		}
		&:hover{
		color: $horgan-ltblue;
	}
	}
	
	// a.home{
	//  &:hover{
	//  	color: $icandigit-blue;
 // 	 }
 //    }

	// a.trees{
	//  &:hover{
	//  	color: $icandigit-green;
 // 	 }
 //    }

 //    a.excavation{
	//  &:hover{
	//  	color: $icandigit-orange;
 // 	 }
 //    }

 //    a.landscapes{
	//  &:hover{
	//  	color: $icandigit-purple;
 // 	 }
 //    }

 //    a.hardscapes{
	//  &:hover{
	//  	color: $icandigit-red;
 // 	 }
 //    }


}

.top-bar-section .has-dropdown > a::after{
	border:none;
}

.top-bar-section ul li, .top-bar-section li.active:not(.has-form) a:not(.button){
	background-color: transparent;

}

.top-bar-section ul li.active a, .top-bar-section ul li.active a span, .top-bar-section li.active:not(.has-form) a:not(.button){
	color: $nesc-blue;
	    @media #{$small-only}{
			border-bottom: 2px solid;
		    margin-left: 30px;
		    padding-left: 0px;
		    width: 82%;
		    font-weight: 600 !important;
		    font-size: 2em;
		 }
	&:hover{
		color: $nesc-blue;
	}

}

.top-bar-section ul li a, .top-bar-section ul li a span{
	font-size: 1rem;
	 //text-shadow: 1px 1px 1px rgba(0,0,0,.5);
	 // color: #000;
	 @media #{$small-only}{
			border-bottom: 2px solid;
		    margin-left: 30px;
		    padding-left: 0px;
		    width: 82%;
		    font-weight: 300 !important;
		    font-size: 2em;
		 }
	 &:hover{
		color: $nesc-blue;
	}
	
}

.top-bar-section ul li > a{
	color: $horgan-ltgrey;
	&:hover{
		color: $nesc-blue;
	}
}

.top-bar-section ul li a.homeIcon{
	background-image: url("../../images/shared/home.png") !important;
	background-repeat: no-repeat !important;
	background-size: 20px 20px !important;
	background-position: 0px 10px !important;
	//padding-right: 2rem !important; //fixed for desktop nav

	
	
	//background-color: red;
}

.top-bar-section ul li.active a.homeIcon{
	background-image: url("../../images/shared/home-active.png") !important;
}

.expanded .top-bar-section ul li > a, .expanded .top-bar-section ul li > a span{
	color: $horgan-ltgrey;
	text-shadow: none;

	&:hover{
		color: $nesc-blue;
	}

}

.expanded{

	height: 350px !important;
	background-color: #fff !important;
	li.name{
			h1{
				a{color: $icandigit-grey;}
			}
		}
	a.homeIcon{margin-left: .9375rem};
}

li.moved ul > li.parent-link a{
	display: none !important;
}

.top-bar-section .has-dropdown.moved>.dropdown{height: 207px !important;}

.top-bar-section .has-dropdown>a {
	@media #{$medium-up}{
		padding: 0 .9375rem !important;
	}
}
.transparentNav{
	
	background: transparent;
}

.top-bar-section .dropdown{
	background-color: #fff;
	border: 1px #e8e8e8 solid;
}

.top-bar-section .dropdown li:not(.has-form):not(.active)>a:not(.button){
	background-color: #fff;
	color: $nesc-blue;
	&:hover{
		background-color: #fff;
		text-decoration: underline;
		color: $nesc-blue;
	}

}

.top-bar-section .dropdown li:not(.has-form).active>a:not(.button){
color: $nesc-red;
}

.whiteNav{
	background: #fff;
	box-shadow: 0px 1px 5px $icandigit-grey;
	@media #{$medium-up} {
		@include single-transition(background, 1s, ease-in-out);
	}
	.top-bar-section ul li > a, .expanded .top-bar-section ul li > a, .expanded .top-bar-section ul li > a span{
		color: $icandigit-grey;
		text-shadow: none;

		&:hover{
		color: $nesc-blue;
	}
		
		
		

	}
	.top-bar-section ul li a.active span, .top-bar-section ul li a span{
			text-shadow: none;
			// color: $icandigit-blue;
		}


	ul.title-area{
		li.name{
			h1{
				a{color: $icandigit-grey;}
			}
		}
		li.menu-icon{
			a{ 

				span{
					color: $icandigit-grey;
				}

				&:after{
					color: $icandigit-grey;
				}
			}
			a span::after{
				box-shadow: 0 0 0 1px $icandigit-grey, 0 7px 0 1px $icandigit-grey, 0 14px 0 1px $icandigit-grey;
			}
		}
	}
	
}

.whiteBg{
	
		background-color: #fff !important;
}


a#site-name{
	text-align: left;
}



.top-bar .toggle-topbar.menu-icon a{
font-family: $lato;
color: #fff;
}

.top-bar .toggle-topbar.menu-icon a span::after{

	// box-shadow: 0 0 0 1px #fff,0 7px 0 1px #fff,0 14px 0 1px #fff;
	box-shadow: 0 0 0 1px $icandigit-grey,0 7px 0 1px $icandigit-grey,0 14px 0 1px $icandigit-grey !important;
}

.top-bar-section .dropdown li.title h5 a{
	color: $horgan-dkgrey;
}


a#site-name{
	text-transform: none;
}

.top-bar-section{
background-color: #fff;
}

.top-bar-section ul{
	margin: 0 auto;
}

//nav fix styles
.top-bar-section li:not(.has-form) a:not(.button){
	&:hover{
		color: $nesc-blue;
	}
}


//detail post -- single image or gallery
div.original-image{
	float:right;
}


//Footer
footer{
	margin-top: 40px;
	background-image: url("../../images/home/footer-bg.png");
	background-size: cover;
	@media #{$large-up}{
		//background-position-y: -200px;
		height: 100%;
	}
	color: #fff;
	h2{
		color: #fff;
		font-size: 4rem;
		font-weight: 200;
		@media #{$medium-up}{
			margin-top: 40px;
		}
	}
	p{
		font-size: 1.5rem;

	}
	a.button{
		/* Rectangle 50 Copy 2: */
		
		background-color: rgba($horgan-ltgreen,.5);
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
		color: #fff;
		font-size: 1.25rem;
		&:hover{
			background-color: rgba($horgan-ltgreen,.9);
			text-decoration: none;
		}
		span{
			// color: #fff;
			// opacity: 1;
		}
	}
	
	section.social{
		// background-color: rgba(255,255,255,.5);
		margin-top: 200px;
		position: relative;
		text-align: center;
		// height: 80px;
		// bottom: 10px;
		
		a{
			font-size: 2rem;
		    font-size: 2.25rem;
			padding-right: 10px;
			padding-left: 10px;
			text-decoration: none;
			// color: rbga(92,109,155,1) !important;
			color: rgba(255,255,255,.75);
			&:hover{
				opacity: .5;
			}
		}
	}

	section#sponsors{
		ul {
			text-align: center;
			li{
			list-style: none;
			display: inline-block;
			padding:20px;
				a img{
				width: 150px;
				}
			}
		}
	}

}

// image lists
ul {
	
	li{
		article{
			padding-bottom: 40px;
			border-bottom: 1px dashed #D4CFCF;
		}
		
	}
	@media #{$small-only} {
			li:last-child{
				border-bottom: none;
			}
		}

	li{
		
		

		div{
			position: relative;
		}

		h2{

		text-transform: uppercase;
		color: $horgan-brown;
		font-family: $lato;
		font-weight: 300;
			span.section{

				font-weight: 500;
				color: $nesc-blue;
			}
			span.title{
			text-transform: none;	
			color: $nesc-blue;
			font-weight: 500;
			}
		}

		h3, a h3{
			// background: rgba($horgan-ltgreen,.75);
		    // color: #fff;
		    font-weight: 300;
		    // position: absolute;
		    // padding: 8px;
		    /* font-size: 1.7em; */
		    /* top: 20px; */
		    // margin: 0 12px 0 0;
		    // text-align: right;
		    // width: 100%;
		}
		img.inset-img{
		    position: absolute;
		    height: 33%;
		    width:auto;
		    border: 2px solid #fff;
		    bottom: 20px;
		    right: 20px;
		}
		
		p.read-more{
			text-align: right;
			position: relative;
			top:-20px;
			color: $nesc-blue;
			margin-bottom: -40px;
			&:hover{
				text-decoration: underline;
			}
		}

		
		
	}
}

input#fieldfhhllt{
	height:120px;
	text-align: top;
}

ul#tourneys a{
	text-decoration: none;
	
	&:hover{ opacity: .75;}

}