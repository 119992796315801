//colors

$nesc-blue:rgb(79,94,221);
$nesc-red:rgb(202,38,41);

$coastal-blue:rgb(42,143,199);
$coastal-green:rgb(148,159,56);

$cran-red:rgb(116,5,38);
$cran-blue:rgb(3,0,82);

//new
$horgan-green:rgb(149,161,5);
$horgan-ltgreen:rgb(153,205,0);
$horgan-brown:rgb(69,34,11);

//borrowed

$horgan-purple:rgb(189,87,181);
$horgan-orange:rgb(255,147,0);
$horgan-dkblue:rgb(3,107,153);

$horgan-offwht:rgb(242,247,248);

$horgan-ltgrey:rgb(160,160,160);
$horgan-dkgrey:rgb(102,102,102);

$horgan-ltblue:rgb(55,136,193);


$oyc-blue:rgba(32,48,79,1);
$oyc-blue2:rgba(9,89,118,1);
$oyc-blue3:rgba(21,141,184,1);

$icandigit-blue:rgba(139,196,198,1);
$icandigit-orange:rgba(239,120,14,1);
$icandigit-green:rgba(106,152,47,1);
$icandigit-purple:rgba(146,105,179,1);
$icandigit-red:rgba(172,61,61,1);
$icandigit-grey:rgba(151,151,151,1);
$icandigit-lt-brown:rgba(139,87,42,1);



//fonts
$raleway: 'Raleway', sans-serif;
$lato: 'Lato', sans-serif;
$merriweather: 'Merriweather', serif;
